
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home',
    translate: 'nav.home'
};

const Sales = {
    text: 'Sales',
    link: '/sales',
    icon: 'icon-folder-alt',
    translate: 'nav.sales'
};

const ProfileRate = {
    text: 'Profile Rate',
    link: '/profile-rate',
    icon: 'icon-folder-alt',
    translate: 'nav.profile'
};

const Reseller = {
    text: 'Gestione Reseller',
    link: '/reseller',
    icon: 'icon-badge',
    translate: 'nav.reseller'
};

const Merchant = {
    text: 'Gestione Merchant',
    link: '/seller',
    icon: 'icon-tag',
    translate: 'nav.seller'
};

const Users = {
    text: 'User',
    link: '/user',
    icon: 'icon-people',
    translate: 'nav.users'
};

const Statistics = {
    text: 'Statistics',
    link: '/statistics',
    icon: 'icon-speedometer',
    translate: 'nav.statistics'
};

const Bonifici = {
    text: 'Bonifici',
    link: '/bonifici',
    icon: 'icon-wallet',
    translate: 'nav.bonifici'
};

const Settings = {
    text: 'Settings',
    link: '/settings',
    icon: 'icon-settings',
    translate: 'nav.user-profile'
};

const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        }
    ]
};

const headingMain = {
    text: 'Main Navigation',
    heading: true
};

export const menu = [
    // headingMain,
    Home,
    Sales,
    ProfileRate,
    Reseller,
    Merchant,
    Users,
    Statistics,
    Bonifici,
    Settings
];
