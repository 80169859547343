import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {LoggingService} from "../core/services/log.service";
import {environment} from "../../environments/environment";
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { DatePipe } from '@angular/common'
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private map = new Map<string, any>();

  constructor(private http: HttpClient, private logger: LoggingService , public datepipe: DatePipe , public settings: SettingsService) {}

  storePaymentData(token: string, data: any) {
    this.map.set(token, data);
  }

  getPaymentData(token: string): any {
    return this.map.get(token);
  }

  cleanPaymentData(token: string) {
    this.map.delete(token);
  }
  
  getRateOfTheDay( dayDate : Date|String = null ) : any {
    if( dayDate == null ) {
      let date = new Date();
      // dayDate.
      console.log('TODAY' , this.datepipe.transform( date , 'yyyy-MM-dd' ) )
      dayDate = this.datepipe.transform( date , 'yyyy-MM-dd' )
    }
    
    var path = environment.restBaseUrl + "/payment/processed"

    return this.http
      .post<any[]>( path , { "date" : dayDate }, this.getRequestOptionArgs() )
      .pipe(map(
        (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            const response = res.outcome;
            const dataResponse = res.data;
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            const response = res.outcome;
            return response;
          }
        }
      ) ,
      catchError((error: HttpErrorResponse) => {
        this.logger.log("Service Fail. Error:", error, 200);
        return throwError(error);
      }
      ));
  }

  getRequestOptionArgs(): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
      })
    };
    return httpOptions;
  }

  getAllBonifici( dateRange : Date|String = null ): any {
    const response = { "outcome": "" , "data": [] }
    const data = [
      {"id": 1 , "amount": 120 , "transferDayTime": "2019-05-12 12:00" , "installmentId": 24 , "status":"success" ,"transferDetail": {"receivingId":7 ,"senderId":1}},
      {"id": 2 , "amount": 200 , "transferDayTime": "2019-05-15 13:30" , "installmentId": 28 , "status":"failed" ,"transferDetail": {"receivingId":7 ,"senderId":1}},
      {"id": 3 , "amount": 400 , "transferDayTime": "2019-07-28 16:45" , "installmentId": 30 , "status":"planned" ,"transferDetail": {"receivingId":7 ,"senderId":1}}
    ]
    response.data = data;
    return response;
  }

  fakeResolveData( token ) {
    return {
      "idExternalApp": 1,
      "nameExternalApp": "Test 999",
      "customerEmail": "mario.rossi@email.it",
      "customerName": "Mario",
      "customerSurname": "Rossi",
      "id": "pi_1GjBZcEPro8Lh7bTA4FM8qHb",
      "object": "payment_intent",
      "last_payment_error": {
        "charge": "ch_1GjBZcEPro8Lh7bT6pSoSZEx",
        "code": "card_declined",
        "decline_code": "generic_decline",
        "doc_url": "https://stripe.com/docs/error-codes/card-declined",
        "message": "Your card was declined.",
        "payment_method": {
          "id": "src_1FWloaEPro8Lh7bT3cNbaa1M",
          "object": "payment_method",
          "billing_details": {
            "address": {
              "city": null,
              "country": null,
              "line1": null,
              "line2": null,
              "postal_code": "33333",
              "state": null
            },
            "email": "trediesse@dispostable.com",
            "name": "Tre Diesse",
            "phone": null
          },
          "card": {
            "brand": "visa",
            "checks": {
              "address_line1_check": null,
              "address_postal_code_check": "pass",
              "cvc_check": "pass"
            },
            "country": "US",
            "exp_month": 12,
            "exp_year": 2033,
            "fingerprint": "PIc8X5QpAN54X6K7",
            "funding": "credit",
            "generated_from": null,
            "last4": "3063",
            "three_d_secure_usage": {
              "supported": true
            },
            "wallet": null
          },
          "created": 1571844720,
          "customer": "cus_G2rT4JRRxWs3B8",
          "livemode": false,
          "metadata": {
          },
          "type": "card"
        },
        "type": "card_error"
      },
      "livemode": false,
      "next_action": null,
      "status": "requires_payment_method",
      "amount": 3403,
      "amount_capturable": 0,
      "amount_received": 0,
      "application": null,
      "application_fee_amount": 190,
      "canceled_at": null,
      "cancellation_reason": null,
      "capture_method": "automatic",
      "charges": {
        "object": "list",
        "data": [
          {
            "id": "ch_1GjBZcEPro8Lh7bT6pSoSZEx",
            "object": "charge",
            "amount": 3403,
            "amount_refunded": 0,
            "application": null,
            "application_fee": null,
            "application_fee_amount": 190,
            "balance_transaction": null,
            "billing_details": {
              "address": {
                "city": null,
                "country": null,
                "line1": null,
                "line2": null,
                "postal_code": "33333",
                "state": null
              },
              "email": "trediesse@dispostable.com",
              "name": "Tre Diesse",
              "phone": null
            },
            "calculated_statement_descriptor": "SPAY* MALICK ENTERPRIS",
            "captured": false,
            "created": 1589580008,
            "currency": "eur",
            "customer": "cus_G2rT4JRRxWs3B8",
            "description": null,
            "destination": "acct_1FRyL4DtlhDYJEwd",
            "dispute": null,
            "disputed": false,
            "failure_code": "card_declined",
            "failure_message": "Your card was declined.",
            "fraud_details": {
            },
            "invoice": null,
            "livemode": false,
            "metadata": {
              "idInstalment": "947"
            },
            "on_behalf_of": null,
            "order": null,
            "outcome": {
              "network_status": "declined_by_network",
              "reason": "generic_decline",
              "risk_level": "normal",
              "risk_score": 1,
              "seller_message": "The bank did not return any further details with this decline.",
              "type": "issuer_declined"
            },
            "paid": false,
            "payment_intent": "pi_1GjBZcEPro8Lh7bTA4FM8qHb",
            "payment_method": "src_1FWloaEPro8Lh7bT3cNbaa1M",
            "payment_method_details": {
              "card": {
                "brand": "visa",
                "checks": {
                  "address_line1_check": null,
                  "address_postal_code_check": "pass",
                  "cvc_check": null
                },
                "country": "US",
                "exp_month": 12,
                "exp_year": 2033,
                "fingerprint": "PIc8X5QpAN54X6K7",
                "funding": "credit",
                "installments": null,
                "last4": "3063",
                "network": "visa",
                "three_d_secure": null,
                "wallet": null
              },
              "type": "card"
            },
            "receipt_email": null,
            "receipt_number": null,
            "receipt_url": null,
            "refunded": false,
            "refunds": {
              "object": "list",
              "data": [
              ],
              "has_more": false,
              "total_count": 0,
              "url": "/v1/charges/ch_1GjBZcEPro8Lh7bT6pSoSZEx/refunds"
            },
            "review": null,
            "shipping": null,
            "source": null,
            "source_transfer": null,
            "statement_descriptor": null,
            "statement_descriptor_suffix": "Malick Enterprise - TE",
            "status": "failed",
            "transfer_data": {
              "amount": null,
              "destination": "acct_1FRyL4DtlhDYJEwd"
            },
            "transfer_group": "group_pi_1GjBZcEPro8Lh7bTA4FM8qHb"
          }
        ],
        "has_more": false,
        "total_count": 1,
        "url": "/v1/charges?payment_intent=pi_1GjBZcEPro8Lh7bTA4FM8qHb"
      },
      "client_secret": "pi_1GjBZcEPro8Lh7bTA4FM8qHb_secret_l5600sLiuV5UeVZFpIyzzaDSd",
      "confirmation_method": "automatic",
      "created": 1589580008,
      "currency": "eur",
      "customer": "cus_G2rT4JRRxWs3B8",
      "description": null,
      "invoice": null,
      "metadata": {
        "idInstalment": "947"
      },
      "on_behalf_of": null,
      "payment_method": null,
      "payment_method_options": {
        "card": {
          "installments": null,
          "request_three_d_secure": "automatic"
        }
      },
      "payment_method_types": [
        "card"
      ],
      "receipt_email": null,
      "review": null,
      "setup_future_usage": null,
      "shipping": null,
      "source": null,
      "statement_descriptor": null,
      "statement_descriptor_suffix": "Malick Enterprise - TE",
      "transfer_data": {
        "destination": "acct_1FRyL4DtlhDYJEwd"
      },
      "transfer_group": null
    }
  }

  fakePaymentsMethod(): Observable<any> {
    return this.http.get('../../assets/data/payments_method.json').pipe(map((res: any) => res))
  }

  _getPaymentsMethodOfUser( customer_id ): Observable<any> {
    return this.fakePaymentsMethod().pipe(map((items: any) => { 
      return items.find( ( element ) => element.idCustomer = customer_id )
    }))
  }

  getPaymentsMethodOfUser( elementRequest ) {
    var path = environment.restBaseUrl + '/payment/customer/pm/list';
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          response['outcome'] = outcome;
          return response;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0001" || res.outcome.code === "0002") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // creation of new element
  newSetupIntent( elementRequest ): any {
    var path = environment.restBaseUrl + '/stripe/setup-intent';
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          response['outcome'] = outcome;
          return response;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0001" || res.outcome.code === "0002") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

}