import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../core/services/base.service';
import { Router } from '@angular/router';
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SellerService } from '../../../services/seller.service';
import { SharedService } from '../../../shared/shared.service';
import swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { debug } from 'util';

@Component({
  selector: 'app-tec',
  templateUrl: './tec.component.html',
  styleUrls: ['./tec.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TecComponent implements OnInit {


  tableRecordNumbers:number;
  valForm: FormGroup;

  constructor(private router: Router,
    private userService:UserService,
    public appService: AppService,
    private logService: LoggingService,
    private translate: TranslateService,
    public colors: ColorsService,
    public settings: SettingsService,
    private sellerService: SellerService,
    public sharedService: SharedService, ) {}

  public ngOnInit(): void {
   
  }

  onSubmit(event) {
    
  }

  
}

