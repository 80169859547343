import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;

    constructor(public userblockService: UserblockService) {
        this.user = {
            name: localStorage.getItem("name"),
            picture: localStorage.getItem("picture") == null ? 'assets/img/logo-single.png' : localStorage.getItem("picture"),
            role: localStorage.getItem("role")
        };
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
