import { Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';


function validateVatNumberFactory() {
    return (c: FormControl) => {
        let VATNUMBER_REGEXP =  /^null|^$|^(IT)?[0-9]{11}$/;

        return VATNUMBER_REGEXP.test(c.value) ? null : {
            validateVatNumber: {
              valid: false
            }
        };
    };
}

@Directive({
  selector: '[validateVatNumber]',
  providers: [ { 
        provide: NG_VALIDATORS, 
        useExisting: forwardRef(() => VatNumberValidator),
        multi: true 
    }
  ]
})

export class VatNumberValidator {

    validator: Function;

    constructor(private el: ElementRef) {
        this.validator = validateVatNumberFactory();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }
}