import { Component, OnInit } from '@angular/core';

import { AppRetailsService } from '../services/app-retails.service';

declare var $: any;

@Component({
	selector: 'app-layout',
	templateUrl: './layout_blank.component.html',
	styleUrls: ['./layout_blank.component.scss']
})
export class LayoutBlankComponent implements OnInit {

	retailInfo;

	constructor(
		private retailsService: AppRetailsService
	) { 
    this.retailsService.onVendorInfoReady.subscribe((res) => {
      this.retailInfo = this.retailsService.getVendorInfo('vendorInfo');
      this.getRetail( this.retailInfo.id );
    })
  }

	async ngOnInit() {
	}

	async getRetail( id: number = null ) {
    if(this.retailInfo) {
      this.retailInfo = this.retailInfo;

      var link = $("<link />", {
        rel: "stylesheet",
        type: "text/css",
        href: "assets/css/"+this.retailInfo.color+".css"
      });
      $('#color').html(link);
    }
  }

  ngOnDestroy() {
    this.retailsService.onVendorInfoReady.unsubscribe();
  }

}
