import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster/angular2-toaster';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
//import { AdvanceSearchComponent } from '../core/advance-search/advance-search.component';
//import { ChipComponent } from '../routes/elements/chip/chip.component';
import { SidebarUsersComponent } from '../layout/sidebar-users/sidebar-users.component';
import { VatNumberValidator } from './validators/vat-number.directive';
import { FixedNumberValidator } from './validators/fiscal-code.directive';
import { NgSelectModule } from '@ng-select/ng-select';
//import { StatusSelectComponent } from '../routes/elements/status-select/status-select.component';
import { PasswordValidator } from './validators/password.validator';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
//import { InsertUserComponent } from '../routes/users/insert-user/insert-user.component';

//import { AllUsersFormComponent } from '../routes/forms/all-users-form/all-users-form.component';
//import { ResellerFormComponent } from '../routes/forms/reseller-form/reseller-form.component';
//import { ManagerFormComponent } from '../routes/forms/manager-form/manager-form.component';
//import { ResponsableFormComponent } from '../routes/forms/responsable-form/responsable-form.component';
//import { AgentFormComponent } from '../routes/forms/agent-form/agent-form.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { SmoothHeightComponent } from './smoth-height.component';
//import { MerchantFormComponent } from '../routes/forms/merchant-form/merchant-form.component';
import { FooterComponent } from '../layout/footer/footer.component';
//import { StatusTrashComponent } from '../routes/elements/status-trash/status-trash.component';
import { StripeElementComponent } from '../routes/elements/stripe-element/stripe-element.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { environment } from '../../environments/environment';

import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.domain,
        expiryDays: environment.cookieExpiryDays
    },
    theme: 'block',
    type: 'info',
    position: 'bottom-right',
    content: {
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        dismiss: 'Got it!',
        allow: 'Allow cookies',
        deny: 'Decline',
        link: 'Learn more',
        policy: 'Cookie Policy',
        href: ''
    }
};

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToasterModule,
        NgSelectModule,
        NgxDaterangepickerMd,
        AngularDualListBoxModule,
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    providers: [
        ColorsService
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        //AdvanceSearchComponent,
        
        SidebarUsersComponent,
        VatNumberValidator,
        PasswordValidator,
        FixedNumberValidator,
        /*StatusSelectComponent,
        ChipComponent,
        StatusTrashComponent,
        InsertUserComponent,
        ResellerFormComponent,
        AllUsersFormComponent,
        ResponsableFormComponent,
        AgentFormComponent,
        ManagerFormComponent,
        MerchantFormComponent,*/
        SmoothHeightComponent,
        FooterComponent,
        StripeElementComponent

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        DatepickerModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToasterModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        //AdvanceSearchComponent,
        
        SidebarUsersComponent,
        VatNumberValidator,
        PasswordValidator,
        FixedNumberValidator,
        /*ChipComponent,
        StatusSelectComponent,
        StatusTrashComponent,
        InsertUserComponent,
        ResellerFormComponent,
        AllUsersFormComponent,
        ResponsableFormComponent,
        AgentFormComponent,
        MerchantFormComponent,
        ManagerFormComponent,*/
        SmoothHeightComponent,
        FooterComponent,
        StripeElementComponent

    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
