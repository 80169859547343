import { Routes } from '@angular/router'
import { LayoutComponent } from '../layout/layout.component';
import { LayoutBlankComponent } from '../layout_blank/layout_blank.component';
//import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { PaymentCodeComponent } from './pages/payment-code/payment-code.component';
import { TecComponent } from './pages/tec/tec.component';
import { Error404Component } from './pages/error404/error404.component';
import { AddMethodComponent } from './pages/payments/stripe/add-method/add-method.component';

export const routes: Routes = [
  
  {
    path: '',
    component: LayoutBlankComponent,
    children: [
      { path: 'payment/:token', loadChildren: './pages/payments/payments.module#PaymentsModule', data: { payment: true } },
      { path: 'payment/:token/card/:idCustomerPM/:paymentCode', loadChildren: './pages/payments/payments.module#PaymentsModule', data: { payment: true, card: true } },
      { path: 'payment/instalment/:token/card/:idCustomerPM/:paymentCode', loadChildren: './pages/payments/payments.module#PaymentsModule', data: { instalment: true, card: true } },
      { path: 'payment/:token/pm/:idCustomerPM/:paymentCode', loadChildren: './pages/payments/payments.module#PaymentsModule', data: { payment: true } },
      { path: 'payment/instalment/:token/pm/:idCustomerPM/:paymentCode', loadChildren: './pages/payments/payments.module#PaymentsModule', data: { instalment: true } },
      { path: 'card/new/:idCustomer', component: AddMethodComponent, data: { card: true } },
      { path: 'pm/new/:idCustomer', component: AddMethodComponent },

      { path: ':codeAppExt/make-payment/cf', loadChildren: './pages/make-payments/make-payments.module#MakePaymentsModule', data: { customField: true } },
      { path: ':codeAppExt/make-payment/sale', loadChildren: './pages/make-payments/make-payments.module#MakePaymentsModule', data: { sale: true } },

      { path: 'sale/change/:token', loadChildren: './pages/change-sale/change-sale.module#ChangeSaleModule' },
      
      { path: 'payment',  component: PaymentCodeComponent },
      { path: 'gateway/first-instalment',  component: PaymentCodeComponent },
      { path: 'gateway/first-instalment/:token', loadChildren: './pages/payments/payments.module#PaymentsModule' },
      { path: 'gateway/resolve-payment/:token', loadChildren: './pages/payments/payments.module#PaymentsModule' },
      
      { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
      { path: 'merchant/tos/:token', loadChildren: './pages/register-tos/register-tos.module#RegisterTosModule' },
      { path: 'recover', loadChildren: './pages/recover/recover.module#RecoverModule' },
      //{ path: 'activate/:id', loadChildren: './pages/activate/activate.module#ActivateModule' },
      //{ path: 'activate-sale/:token', loadChildren: './pages/activate-sale/activate-sale.module#ActivateSaleModule' },
      
      { path: 'lock', loadChildren: './pages/lock/lock.module#LockModule' },
      { path: 'tec', component: TecComponent },
      { path: '500', loadChildren: './pages/error500/error500.module#Error500Module' },
    ]
  },
  { path: '404', loadChildren: './pages/error404/error404.module#Error404Module' },

  // Not found
  { path: '**', redirectTo: '404' }

];
