import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService) { }

  getSettingsDetails() {
    var path = environment.restBaseUrl + "/setting/user/me/merchant"
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }
  updateSettingsDetail(settigsDetails){
    var path = environment.restBaseUrl + "/setting/user/me/merchant";
    return this.http
    .put<any[]>(path, settigsDetails, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("updateSettingsDetail", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("updateSettingsDetail", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  uploadSettingsPhoto(file){
    var path = environment.restBaseUrl + "/setting/user/me/logo";
    return this.http
    .post<any[]>(path, file, this.getRequestOptionArgsPhoto())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("uploadSettingsPhoto", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("uploadSettingsPhoto", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
    ));
  }

  getRequestOptionArgs(): any {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Content-Type': 'text/uri-list',
        // ' Content-Encoding': 'application/json',
        'Authorization': localStorage.getItem("token")
      })
    };
    return httpOptions;
  }
  getRequestOptionArgsPhoto(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem("token")
      })
    };
    return httpOptions;
  }
}
