import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslatorService {

    private defaultLanguage: string = 'IT';

    private availablelangs = [
        { code: 'IT', text: 'Italian' },
        { code: 'EN', text: 'English' },
        { code: 'FR', text: 'French' },
        { code: 'ES', text: 'Spanish' },
        { code: 'DE', text: 'German' }
    ];

    lng = new BehaviorSubject<string>('');
    onLngChange = this.lng.asObservable();

    constructor(public translate: TranslateService) {
        // Pulizia localStorage se per qualunque motivo c'è salvata come lingua null a stringa)
        const oldLocalStoLang = localStorage.getItem( 'langPreference' )
        if (oldLocalStoLang != null && oldLocalStoLang.toLowerCase() == 'null') {
            localStorage.removeItem( 'langPreference' )
        }

        const localStoLang = localStorage.getItem( 'langPreference' )

        /* Aggiunto controlli ( localStoLang == localStoLang.toLowerCase() ) per accertarsi che a 
         * tutti i precedenti utenti vengano modificato il valore in localStorage con le nuove chiavi per le lingue. Ovvero in maiuscolo 
        */
        if (!translate.getDefaultLang() && localStoLang == null ) { 
            translate.setDefaultLang(this.defaultLanguage);
        } else { 
            if( localStoLang != null && localStoLang != localStoLang.toLowerCase() ) {
                translate.setDefaultLang( localStoLang )
            } else if( localStoLang != null ) {
                translate.setDefaultLang( localStoLang.toUpperCase() );
            } else  {
                translate.setDefaultLang(this.defaultLanguage);
            }
        }
        this.useLanguage();

    }

    getDefaultLanguage() {
      return this.translate.getDefaultLang();
    }

    useLanguage(lang: string = null) {
        this.translate.use(lang || this.translate.getDefaultLang());
        localStorage.setItem( 'langPreference' , lang || this.translate.getDefaultLang() );
        this.lng.next(lang || this.translate.getDefaultLang());
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

    getLanguage() {
        return localStorage.getItem( 'langPreference' );
    }

}
