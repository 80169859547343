import { Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';


function fixedNumberFactory() {
    return (c: FormControl) => {
        let FIXEDNUMBER_REGEXP = /^null|^$|^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;

        return FIXEDNUMBER_REGEXP.test(c.value) ? null : {
            validateFixedNumber: {
              valid: false
            }
        };
    };
}

@Directive({
  selector: '[validateFixedNumber]',
  providers: [ { 
        provide: NG_VALIDATORS, 
        useExisting: forwardRef(() => FixedNumberValidator),
        multi: true 
    }
  ]
})

export class FixedNumberValidator {

    validator: Function;

    constructor(private el: ElementRef) {
        this.validator = fixedNumberFactory();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }
}