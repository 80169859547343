import { Injectable } from '@angular/core';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';


@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() {

  }
  export(data,name){
    new Angular5Csv(data, name);
  }
}
