import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService) { }

getAllUsers(queryParams = null) {
  var path = environment.restBaseUrl + "/user/getAll/"
  if(queryParams){
    path +="?" + queryParams
  }

  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));

}
getUserDetails(idUser) {
  var path = environment.restBaseUrl + "/user/"+idUser
  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));

}
insertUser(user){
  var path = environment.restBaseUrl + "/user";
  return this.http
  .post<any[]>(path, user, this.getRequestOptionArgs())
  .pipe(map(
  (response: HttpResponse<any>) => {
      const res: any = response;

      if (res.outcome.success === true) {
        this.logger.log("insertUser", " SUCCESS", 200);
        return res;
      } else {
        this.logger.log("insertUser", " FAILURE", 200);
        return res;
      }
    }
  ), catchError((errorResponse: HttpErrorResponse) => {
    let res: any = errorResponse.error;
    if (res.outcome.code === "0005" || res.outcome.code === "0007") {
      this.settings.sessionExpired();
    }
    return throwError(errorResponse.error);
  }
  ));
}

getProfileDatas() {
  var path = environment.restBaseUrl + "/user/profile"

  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];

      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
    let res: any = errorResponse.error;
    if (res.outcome.code === "0005" || res.outcome.code === "0007") {
      this.settings.sessionExpired();
    }
    return throwError(errorResponse.error);
  }
  ));
}
getUserDatas(idUser) {
  var path = environment.restBaseUrl + "/user/"+idUser

  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];

      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
    let res: any = errorResponse.error;
    if (res.outcome.code === "0005" || res.outcome.code === "0007") {
      this.settings.sessionExpired();
    }
    return throwError(errorResponse.error);
  }
  ));
}
updateUserDatas(user){
  var path = environment.restBaseUrl + "/user";
  return this.http
  .put<any[]>(path, user, this.getRequestOptionArgs())
  .pipe(map(
  (response: HttpResponse<any>) => {
      const res: any = response;

      if (res.outcome.success === true) {
        this.logger.log("updateUserDatas", " SUCCESS", 200);
        return res;
      } else {
        this.logger.log("updateUserDatas", " FAILURE", 200);
        return res;
      }
    }
  ), catchError((errorResponse: HttpErrorResponse) => {
    let res: any = errorResponse.error;
    if (res.outcome.code === "0005" || res.outcome.code === "0007") {
      this.settings.sessionExpired();
    }
    return throwError(errorResponse.error);
  }
  ));
}
changeStatus(status){
  var path = environment.restBaseUrl + "/user/status";
  return this.http
  .put<any[]>(path, status, this.getRequestOptionArgs())
  .pipe(map(
  (response: HttpResponse<any>) => {
      const res: any = response;

      if (res.outcome.success === true) {
        this.logger.log("insertUser", " SUCCESS", 200);
        return res;
      } else {
        this.logger.log("insertUser", " FAILURE", 200);
        return res;
      }
    }
  ), catchError((errorResponse: HttpErrorResponse) => {
    let res: any = errorResponse.error;
    if (res.outcome.code === "0005" || res.outcome.code === "0007") {
      this.settings.sessionExpired();
    }
    return throwError(errorResponse.error);
  }
  ));
}
changePwd(pwd){
  var path = environment.restBaseUrl + "/user/change-password";
  return this.http
  .put<any[]>(path, pwd, this.getRequestOptionArgs())
  .pipe(map(
  (response: HttpResponse<any>) => {
      const res: any = response;

      if (res.outcome.success === true) {
        this.logger.log("updatePwd", " SUCCESS", 200);
        return res;
      } else {
        this.logger.log("updatePwd", " FAILURE", 200);
        return res;
      }
    }
  ), catchError((errorResponse: HttpErrorResponse) => {
    let res: any = errorResponse.error;
    if (res.outcome.code === "0005" || res.outcome.code === "0007") {
      this.settings.sessionExpired();
    }
    return throwError(errorResponse.error);
  }
  ));
}
getUserProfiles(idUser) {
  var path = environment.restBaseUrl + "/user/"+idUser+"/getAll"
  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));
}
getResponsabileLevel(idReseller) {
  var path = environment.restBaseUrl + "/reseller/"+idReseller+"/getResponsible"
  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));
}
getResponsabileAgentLevel(idUser) {
  var path = environment.restBaseUrl + "reseller/" +idUser+ "/getAgent"
  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));
}

getResellerUsersList() {
  var path = environment.restBaseUrl + "/merchant/user/list"
  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));
}

getMerchantList() {
  var path = environment.restBaseUrl + "/merchant/getAll"
  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));
}

getAgentTable(idReseller) {
  var path = environment.restBaseUrl + "/reseller/" +idReseller+ "/getAgent"
  // console.log(idReseller)
  return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
    (response: HttpResponse<any>) => {
      const outcome = response['outcome'];
      const dataResponse = response['data'];
      //this.totalItems = dataResponse['total'];
      if (outcome.success === true) {
        this.logger.log("Service:", "SUCCESS", 200);
        return dataResponse;
      } else {
        this.logger.log("Service:", "FAILURE", 200);
        outcome.message = this.settings.manageErrorMsg(outcome);
        return outcome;
      }

    }
  ), catchError((errorResponse: HttpErrorResponse) => {
      let res: any = errorResponse.error;
      if (res.outcome.code === "0005" || res.outcome.code === "0007") {
        this.settings.sessionExpired();
      }
      return throwError(errorResponse.error);
    }
  ));
}

// getAgentList() {
//   var path = environment.restBaseUrl + "/user/getAll/"
//   return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
//     (response: HttpResponse<any>) => {
//       const outcome = response['outcome'];
//       const dataResponse = response['data'];
//       this.totalItems = dataResponse['total'];
//       if (outcome.success === true) {
//         this.logger.log("Service:", "SUCCESS", 200);
//         return dataResponse;
//       } else {
//         this.logger.log("Service:", "FAILURE", 200);
//         outcome.message = this.settings.manageErrorMsg(outcome);
//         return outcome;
//       }

//     }
//   ), catchError((errorResponse: HttpErrorResponse) => {
//       let res: any = errorResponse.error;
//       if (res.outcome.code === "0005" || res.outcome.code === "0007") {
//         this.settings.sessionExpired();
//       }
//       return throwError(errorResponse.error);
//     }
//   ));
// }

getRequestOptionArgs(): any {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Content-Type': 'text/uri-list',
      // ' Content-Encoding': 'application/json',
      'Authorization':  localStorage.getItem("token")
    })
  };
  return httpOptions;
}
}
