export enum FilterPageType {
  AnalisiVendite = "sale-analysis",
  AnalisiRate = "installment-analysis",
  RateDelGiorno = "stats-installment",
  RiepilogoFee = "stats-fee",
  EstrattoConto = "bank-statement"
}

export enum PaymentMethodType {
  CARD = "card",
  SEPA_DEBIT = "sepa_debit",
  PAYPAL = "paypal",
  KLARNA = "klarna"
}

export enum CustomerPaymentMethodType {
  CARD = "card",
  SEPA = "sepa"
}