import { NgModule } from '@angular/core';
import { FooterComponent } from '../layout/footer/footer.component';
import { LayoutBlankComponent } from './layout_blank.component';


import { SharedModule } from '../shared/shared.module';
import { LangFooterComponent } from './lang-footer/lang-footer.component';

@NgModule({
    imports: [
      SharedModule
    ],
    providers: [
    ],
    declarations: [
      LayoutBlankComponent,
      LangFooterComponent,

    ],
    exports: [
      LayoutBlankComponent
    ]
})
export class LayoutBlankModule { }
