import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppRetailsService {
  @Output() onVendorInfoReady = new EventEmitter<any>();

  private storage = new Map<String, any>();

  constructor( private http: HttpClient ) { }

  vendors(): Observable<any> {
    if( environment.production ) {
      return this.http.get('../../../assets/data/appExt-configuration.prod.json').pipe(map((res: any) => res))
    } else {
      return this.http.get('../../../assets/data/appExt-configuration.json').pipe(map((res: any) => res))
    }
  }

  getVendorById( id: number ): Observable<any> {
    return this.vendors().pipe(map(
      (items: any[]) => {
        this.storeVendorInfo( 'vendorInfo' , items.find((item) => item.id === id ) )
        this.onVendorInfoReady.emit();
        return items.find((item) => item.id === id )
      }
    ))
  }

  storeVendorInfo( key: string , value: any) {
    this.storage.set( key , value );
  }

  getVendorInfo(key: string): any {
    return this.storage.get(key);
  }

  cleanPaymentData(key: string) {
    this.storage.delete(key);
  }

}
