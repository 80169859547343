import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatorService } from '../../core/translator/translator.service';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { SharedService } from '../../shared/shared.service';
import { AuthService } from '../../core/services/auth.service';

export interface NavItem {
  name: String
  link: String
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profile: any;
  navItems = new Array<NavItem>();
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  selectedLanguage = "";
  pendingLogoSrc: any;
  logoPath: any;
  isNavSearchVisible: boolean;
  avaibleLangs =  [];
  @ViewChild('fsbutton') fsbutton;  // the fullscreen button
  user = {
    "name": "",
    "surname": "",
    "type": "",
    "capitals":""
  }

  constructor(private authService:AuthService,
              public menu: MenuService,
              public userblockService: UserblockService,
              public settings: SettingsService,
              public router: Router,
              public translator: TranslatorService,
              private sharedService: SharedService) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    var defLang = localStorage.getItem( 'langPreference' );
    if( defLang == null ) {
      // this.selectedLanguage = this.getLangs()[0].code;
      this.selectedLanguage = this.translator.getDefaultLanguage();
    } else {
      this.selectedLanguage = defLang;
    }
    

    this.profile = localStorage.getItem("profile");
    this.logoPath = localStorage.getItem('logo')

    if(this.logoPath == null) {
      this.logoPath = 'assets/img/service-pay-logo.png';
    } else {
      if(this.profile == 'SUPERADMIN' || this.profile == 'RESELLER') {
        this.logoPath = 'assets/img/service-pay-logo.png';
      }
    }

    this.sharedService.onChangedLogoObserver.subscribe((res: any) => {
      this.pendingLogoSrc = res;
    })
  }
  ngOnInit() {
    this.initMenu();
    this.user.name = localStorage.getItem("name");
    this.user.surname = localStorage.getItem("surname");
    if( this.user.name && this.user.surname ) {
      this.user.capitals = this.user.name.charAt(0) + this.user.surname.charAt(0);
    }

    this.isNavSearchVisible = false;
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    if (localStorage.getItem('logo')) {
      this.logoPath = localStorage.getItem('logo')
    }
    else {
      this.logoPath = 'assets/img/service-pay-logo.png'
    }

    this.avaibleLangs = this.getLangs();

  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.useLanguage(value);
    this.selectedLanguage = value;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  toggleSidebarUtenti() {
    // console.log( 'STATUS SIDEBAR UTENTI' , this.sharedService.toggleSidebarUtenti() )
  }

  initToggleSidebarUtenti() {

  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  initMenu() {

    switch (this.profile) {
      case 'SUPERADMIN':
        this.navItems = [
          {
            "name": "nav.home",
            "link": "/home"
          }, {
            "name": "nav.sales",
            "link": "/sales"
          },{
            "name": "nav.profile-rate",
            "link": "/profile-rate"
          }, {
            "name": "nav.reseller",
            "link": "/reseller"
          }, {
            "name": "nav.seller",
            "link": "/seller"
          }
          , {
            "name": "nav.users",
            "link": "/user"
          },
          {
            "name": "nav.statistics",
            "link": "/statistics"
          },
          {
            "name": "nav.bonifici",
            "link": "/bonifici"
          },

         ]
        break;

      case 'RESELLER':
        this.navItems = [
          {
            "name": "nav.home",
            "link": "/home"
          }, {
            "name": "nav.sales",
            "link": "/sales"
          },
        ]
        break
      case 'MANAGER':
        this.navItems = [
          {
            "name": "nav.home",
            "link": "/home"
          }, {
            "name": "nav.sales",
            "link": "/sales"
          }
          , {
            "name": "nav.profile-rate",
            "link": "/profile-rate"
          }
          , {
            "name": "nav.users",
            "link": "/user"
          }
          // , {
          //   "name": "nav.settings",
          //   "link": "/settings"
          // }
          ,{
            "name": "nav.statistics",
            "link": "/statistics"
          }
          , {
            "name": "nav.bonifici",
            "link": "/bonifici"
          },
        ]
        break
      case 'RESPONSIBLE':
        this.navItems = [
          {
            "name": "nav.home",
            "link": "/home"
          }, {
            "name": "nav.sales",
            "link": "/sales"
          }
          , {
            "name": "nav.users",
            "link": "/user"
          },
          // {
          //   "name": "nav.statistics",
          //   "link": "/statistics"
          // },
          {
            "name": "nav.bonifici",
            "link": "/bonifici"
          },
        ]
        break
      case 'AGENT':
        this.navItems = [
          {
            "name": "nav.home",
            "link": "/home"
          }, {
            "name": "nav.sales",
            "link": "/sales"
          }]
        break
    }
  }

  logout() {
    let maxLoggedinDays = localStorage.getItem('maxLoggedinDays');
    let tableRecordNumbers= localStorage.getItem('tableRecordNumbers');
    let saveUsername = localStorage.getItem("saveUsername")

    var temp_langPref = localStorage.getItem('langPreference'); 

    this.settings.sessionExpired();
    this.authService.logout();

    localStorage.setItem( 'langPreference' , temp_langPref );

    if(maxLoggedinDays)
      localStorage.setItem("maxLoggedinDays",maxLoggedinDays)
    
    if(tableRecordNumbers)
      localStorage.setItem("tableRecordNumbers",tableRecordNumbers)
    
    localStorage.setItem("saveUsername",saveUsername)
    this.router.navigate(["/login/0"]);
  }
}
