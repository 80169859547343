import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './error404.component';
import { SharedModule } from '../../../shared/shared.module';

const routes: Routes = [
    { path: '', component: Error404Component },
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    declarations: [Error404Component],
    exports: [
        RouterModule
    ]
})
export class Error404Module { }
