import { Component, HostBinding, OnInit } from '@angular/core';
declare var $: any;
import { setTheme } from 'ngx-bootstrap/utils';

import { SettingsService } from './core/settings/settings.service';

import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };

    constructor(public settings: SettingsService,
        // private cookieConsent: NgcCookieConsentService,
        private translate: TranslateService) {
        this.translate.onLangChange.subscribe(() => {
            // this.cookieStringTranslate();
        })
    }

    ngOnInit() {
      setTheme('bs4'); // or 'bs4'
        $(document).on('click', '[href="#"]', e => e.preventDefault());
    }

    cookieStringTranslate() {
        this.translate
            .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy', 'cookie.href'])
            .subscribe(data => {
                // this.cookieConsent.getConfig().content = this.cookieConsent.getConfig().content || {} ;
                // // Override default messages with the translated ones
                // this.cookieConsent.getConfig().content.header = data['cookie.header'];
                // this.cookieConsent.getConfig().content.message = data['cookie.message'];
                // this.cookieConsent.getConfig().content.dismiss = data['cookie.dismiss'];
                // this.cookieConsent.getConfig().content.allow = data['cookie.allow'];
                // this.cookieConsent.getConfig().content.deny = data['cookie.deny'];
                // this.cookieConsent.getConfig().content.link = data['cookie.link'];
                // this.cookieConsent.getConfig().content.policy = data['cookie.policy'];
                // this.cookieConsent.getConfig().content.href = data['cookie.href'];
        
                // this.cookieConsent.destroy(); // remove previous cookie bar (with default messages)
                // this.cookieConsent.init(this.cookieConsent.getConfig()); // update config with translated messages
            });
    }
}
