import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TecComponent } from './tec.component';
import { SharedModule } from '../../../shared/shared.module';

const routes: Routes = [
    { path: '', component: TecComponent },
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    declarations: [TecComponent],
    exports: [
        RouterModule
    ]
})
export class TecModule { }
