import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../core/services/base.service';
import {PaymentService} from "../../../services/payment.service";
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-code',
  templateUrl: './payment-code.component.html',
  styleUrls: ['./payment-code.component.scss']
})

export class PaymentCodeComponent implements OnInit {

  public  responseArrived: boolean;
  public  errorMessage: any;
  private token: string;

  public profileForm = this.formBuilder.group({
    code  : ['']
  });

  constructor(private route: ActivatedRoute, private appService: AppService, private router: Router, private paymentService: PaymentService, private formBuilder: FormBuilder,) {}

  ngOnInit() {

  }

  onSubmit() {
    this.router.navigate(['first-instalment/' + this.profileForm.value.code]);
  }

}
