import { Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';


function validatePasswordFactory() {
  return (c: FormControl) => {
    // let PASSWORD_REGEXP =  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    let PASSWORD_REGEXP =  /^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*[^a-zA-Z0-9]).{8,}$/;
    // let PASSWORD_REGEXP = /^[0-9a-zA-Z]{8,}$/;
    return PASSWORD_REGEXP.test(c.value) ? null : {
      validatePass: {
        valid: false
      }
    };
  };
}

@Directive({
  selector: '[validatePass]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PasswordValidator),
    multi: true
  }
  ]
})

export class PasswordValidator {

  validator: Function;

  constructor(private el: ElementRef) {
    this.validator = validatePasswordFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

}
